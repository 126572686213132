<template>
  <div>
    <QuizQuestions
      :lessonId="lessonId"
      :headers="lessonsQuizHeader"
      :questions="lessonDto.questions"
      :isPlus="true"
    ></QuizQuestions>
  </div>
</template>

<script>
import { mapState } from "vuex";
import QuizQuestions from "../quiz/QuizQuestions.vue";

export default {
  components: {
    QuizQuestions,
  },
  props: ["id", "lessonId"],
  computed: {
    ...mapState({
      lessonDto: (state) => state.lessons.lessonDto,
      lessonsQuizHeader: (state) => state.lessons.lessonsQuizHeader,
    }),
  },
};
</script>
