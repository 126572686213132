<template>
    <div class="document-lesson">
        <div v-if="this.lessonType == 1 || this.lessonType == 2">
            <b-row>
                <b-col lg="8" cols="12">
                    <b-card>
                        <div>
                            <div class="d-flex justify-content-between p-1">
                                <h4>تفاصيل الفيديو</h4>
                            </div>
                            <div class="m-10">
                                <ek-input-image
                                    name=" file"
                                    label="غلاف الفيديو"
                                    :image="lessonDto.thumbUrl"
                                    @input="getFile"
                                >
                                    <h5>انقر لتحميل غلاف الفيديو</h5>
                                </ek-input-image>
                            </div>
                            <div class="d-flex justify-content-between p-1">
                                <h4>الفيديو</h4>
                            </div>
                            <div v-if="this.lessonType == 2">
                                <p>اضغط لتحميل الملف</p>
                                <a :href="lessonDto.url">
                                    <div class="d-flex justify-content-center">
                                        <img
                                            src="https://www.cbpr.me/wp-content/uploads/2019/07/placeholder-pdf.jpg"
                                            width="10%"
                                            alt=""
                                        />
                                    </div>
                                </a>
                            </div>
                            <div v-else>
                                <b-dropdown
                                    size="lg"
                                    variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    class="position-absolute"
                                    style="z-index: 999"
                                >
                                    <template #button-content>
                                        <div class="rounded-circle" style="background-color: #882ff6;">
                                            <unicon
                                                name="ellipsis-v"
                                                fill="#fff"
                                            ></unicon>
                                        </div>
                                    </template>
                                    <b-dropdown-item
                                        href="#"
                                        ref="myUploader"
                                        @click="fileWindowApi(storeFile)"
                                    >
                                        <unicon
                                            name="edit-alt"
                                            fill="#882FF6"
                                            style="width: 25px"
                                        ></unicon>
                                        <span>تعديل الفيديو</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div>
                                <video
                                    v-if="
                                        lessonDto.isDecrypted && lessonDto.url
                                    "
                                    preload="none"
                                    controls
                                    class="w-100"
                                    :src="lessonDto.url"
                                ></video>
                            </div>

                            <div
                                v-if="lessonType == 1 || lessonType == 2"
                                class="video-dtails my-2"
                            >
                                <h4 v-if="this.lessonType == 1">
                                    تفاصيل فيديو
                                </h4>
                                <h4 v-else-if="this.lessonType == 2">
                                    تفاصيل الملف
                                </h4>
                                <ek-input-text
                                    :label="
                                        lessonType == 1
                                            ? 'عنوان الفيديو  '
                                            : 'عنوان الملف'
                                    "
                                    name="عنوان الفيديو"
                                    v-model="lessonDto.videoName"
                                >
                                </ek-input-text>
                                <ek-input-textarea
                                    label="التوصيف"
                                    name="التوصيف"
                                    v-model="lessonDto.description"
                                >
                                </ek-input-textarea>
                            </div>
                        </div>

                        <template #footer>
                            <div class="d-flex justify-content-between w-100">
                                <div>
                                    <b-button
                                        @click="onUpdate"
                                        variant="primary"
                                    >
                                        تعديل
                                    </b-button>
                                    <b-button
                                        variant="flat-danger"
                                        class="mx-1"
                                        @click="deleteLesson(lessonDto.id)"
                                    >
                                        حذف
                                    </b-button>
                                </div>
                                <b-button
                                    variant="outline-primary"
                                    @click="$router.go(-1)"
                                    >رجوع
                                </b-button>
                            </div>
                        </template>
                    </b-card>
                </b-col>
                <b-col lg="4" cols="12">
                    <LessonQuistions
                        :lessonId="lessonDto.id"
                        :quistions="lessonDto.questions"
                        :lessonType="lessonType"
                    >
                    </LessonQuistions>
                </b-col>
            </b-row>
        </div>

        <!--بث مباشر-->
        <div v-if="this.lessonType == 4">
            <b-card>
                <div class="d-flex justify-content-between p-1">
                    <h4>تفاصيل الفيديو</h4>
                    <div>
                        <b-button
                            @click="onUpdate"
                            variant="primary"
                            :disabled="lessonDto.isFinished ? true : false"
                        >
                            تعديل
                        </b-button>
                    </div>
                </div>
                <div>
                    <b-row>
                        <b-col lg="6">
                            <ek-input-text
                                label="عنوان الفيديو"
                                name="عنوان الفيديو"
                                v-model="lessonDto.name"
                            >
                            </ek-input-text>
                        </b-col>
                        <b-col lg="3">
                            <ek-input-text
                                label="رابط الفيديو"
                                name="رابط الفيديو"
                                v-model="lessonDto.url"
                            >
                            </ek-input-text>
                        </b-col>
                        <b-col
                            lg="3"
                            class="d-flex flex-column justify-content-center"
                        >
                            <label for="">تاريخ البث</label>
                            <ek-date-picker
                                class="w-100"
                                placeholder="yyyy/mm/dd"
                                clock
                                time_24hr
                                id="dOB"
                                v-model="lessonDto.scheduleDate"
                            />
                        </b-col>
                    </b-row>
                    <div>
                        <b-row>
                            <b-col lg="12">
                                <ek-input-textarea
                                    label="التوصيف"
                                    name="التوصيف"
                                    v-model="lessonDto.description"
                                >
                                </ek-input-textarea>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-if="lessonDto.questions.length">
                        <div class="d-flex justify-content-between m-1">
                            <h4>اسئلة على الفيديو</h4>
                            <div>
                                <b-badge pill variant="primary" class="mr-1">{{
                                    lessonDto.questions.length
                                }}</b-badge
                                >سؤال
                            </div>
                        </div>
                        <div
                            v-for="item in lessonDto.questions"
                            :key="item.id"
                            class="my-50 p-1 rounded-lg overflow-hidden shadow border-card"
                        >
                            <div class="d-flex justify-content-between w-100">
                                <div>
                                    <b-avatar
                                        variant="primary"
                                        size="sm"
                                    ></b-avatar>
                                    {{ item.studentName }}
                                </div>
                                <div>
                                    {{ item.time }}
                                    <unicon
                                        name="clock"
                                        fill="gray"
                                        width="22"
                                        height="22"
                                    />
                                </div>
                            </div>
                            <div class="mt-1 ml-2">{{ item.content }}</div>
                        </div>
                    </div>
                    <div v-else class="d-flex justify-content-center p-2">
                        <h5>لا يوجد اسئلة</h5>
                    </div>
                </div>
                <div>
                    <b-row>
                        <b-col lg="12" cols="12">
                            <div
                                class="d-flex items-center position-relative w-100"
                            >
                                <svg
                                    width="79"
                                    height="82"
                                    viewBox="0 0 79 82"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g filter="url(#filter0_d_28430_97329)">
                                        <rect
                                            x="12.6631"
                                            y="8"
                                            width="46.3371"
                                            height="49.2632"
                                            rx="23.1685"
                                            fill="url(#paint0_linear_28430_97329)"
                                        />
                                    </g>
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M23.6406 32.0197C23.6406 25.3655 28.7743 19.3691 35.859 19.3691C42.7852 19.3691 48.0286 25.2516 48.0286 31.9817C48.0286 39.7871 41.8828 44.6323 35.8346 44.6323C33.8348 44.6323 31.6155 44.0757 29.8352 42.9877C29.2133 42.5956 28.6889 42.3046 28.0183 42.5323L25.5551 43.2913C24.9332 43.4937 24.3723 42.9877 24.5552 42.3046L25.3722 39.4709C25.5063 39.0787 25.4819 38.6612 25.2868 38.3323C24.2381 36.3335 23.6406 34.145 23.6406 32.0197ZM30.1885 33.6516C29.3349 33.6516 28.6277 32.9179 28.6277 32.0324C28.6277 31.1342 29.3227 30.4131 30.1885 30.4131C31.0543 30.4131 31.7493 31.1342 31.7493 32.0324C31.7493 32.9179 31.0543 33.639 30.1885 33.6516ZM39.8702 32.0324C39.8702 32.9179 40.5653 33.6516 41.4311 33.6516C42.2968 33.6516 42.9919 32.9179 42.9919 32.0324C42.9919 31.1342 42.2968 30.4131 41.4311 30.4131C40.5653 30.4131 39.8702 31.1342 39.8702 32.0324ZM34.2497 32.0199C34.2497 32.9181 34.9447 33.6391 35.8105 33.6518C36.6763 33.6518 37.3713 32.9181 37.3713 32.0325C37.3713 31.1343 36.6763 30.4132 35.8105 30.4132C34.9569 30.4006 34.2497 31.1343 34.2497 32.0199Z"
                                        fill="white"
                                    />
                                    <defs>
                                        <filter
                                            id="filter0_d_28430_97329"
                                            x="0.663086"
                                            y="0"
                                            width="78.3369"
                                            height="81.2627"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset dx="4" dy="8" />
                                            <feGaussianBlur stdDeviation="8" />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.262745 0 0 0 0 0.392157 0 0 0 0 0.968627 0 0 0 0.3 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_28430_97329"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_28430_97329"
                                                result="shape"
                                            />
                                        </filter>
                                        <linearGradient
                                            id="paint0_linear_28430_97329"
                                            x1="72.3666"
                                            y1="73.3181"
                                            x2="27.1262"
                                            y2="0.237938"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stop-color="#0052D4" />
                                            <stop
                                                offset="0.0001"
                                                stop-color="#4364F7"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="#6FB1FC"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>

                                <b-textarea
                                    class="border-0"
                                    placeholder="اكتب الإجابة عن السؤال هنا ..."
                                    name="comment"
                                    v-model="answer"
                                ></b-textarea>

                                <b-button
                                    variant="transparent"
                                    @click="submitTeacherQuestion"
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M19.4354 0.581983C18.9352 0.0685981 18.1949 -0.122663 17.5046 0.0786645L1.408 4.75952C0.679698 4.96186 0.163487 5.54269 0.0244302 6.28055C-0.117628 7.0315 0.378575 7.98479 1.02684 8.38342L6.0599 11.4768C6.57611 11.7939 7.24238 11.7144 7.66956 11.2835L13.4329 5.4843C13.723 5.18231 14.2032 5.18231 14.4934 5.4843C14.7835 5.77623 14.7835 6.24935 14.4934 6.55134L8.71999 12.3516C8.29181 12.7814 8.21178 13.4508 8.52691 13.9702L11.6022 19.0538C11.9623 19.6577 12.5826 20 13.2628 20C13.3429 20 13.4329 20 13.513 19.9899C14.2933 19.8893 14.9135 19.3558 15.1436 18.6008L19.9156 2.52479C20.1257 1.84028 19.9356 1.09537 19.4354 0.581983Z"
                                            fill="#5685F9"
                                        />
                                    </svg>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div class="d-flex justify-content-between w-100 pt-2">
                    <div>
                        <b-button
                            variant="outline-danger"
                            class="mx-1"
                            @click="deleteLesson(lessonDto.id)"
                        >
                            حذف البث المباشر
                        </b-button>
                        <b-button variant="primary" v-b-modal.modal-1
                            >التحويل إلى درس</b-button
                        >
                        <ValidationObserver ref="form">
                            <b-modal
                                @ok="handleOk"
                                id="modal-1"
                                title="تحويل إلى درس"
                            >
                                <ek-input-image
                                    class="question-image"
                                    ref="videoUpload"
                                    @input="getLessonFile"
                                    label="الفيديو"
                                >
                                    <h5>اسحب الملف او انقر للتحميل</h5>
                                </ek-input-image>
                                <ek-input-image
                                    class="question-image"
                                    ref="thumbfile"
                                    @input="getLessonThumb"
                                    label="غلاف الفيديو"
                                >
                                    <h5>" غلاف الفيديو"</h5>
                                </ek-input-image>
                            </b-modal>
                        </ValidationObserver>

                        <b-button
                            class="ml-1"
                            @click="finishLiveLesson(lessonDto.id)"
                            variant="primary"
                            :disabled="lessonDto.isFinished ? true : false"
                        >
                            {{
                                lessonDto.isFinished
                                    ? "تم إنهاء البث المباشر "
                                    : " إنهاء البث المباشر "
                            }}
                        </b-button>
                    </div>
                    <b-button variant="outline-primary" @click="$router.go(-1)"
                        >تراجع
                    </b-button>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { mapState, mapActions } from "vuex";
import LessonQuistions from "../../LessonQuistions.vue";
import { fileWindowApi } from "@core/utils/uploader";

// import signalr from "@microsoft/signalr";
export default {
    data: () => ({
        answer: "",
        signalRConnection: null,
    }),
    props: ["lessonType", "lessonId"],
    components: {
        LessonQuistions,
    },
    computed: {
        ...mapState({
            lessonDto: ({ lessons }) => lessons.lessonDto,
            uploadLiveLesson: ({ lessons }) => lessons.uploadLiveLesson,
        }),
    },
    methods: {
        fileWindowApi,
        storeFile(e) {
            this.lessonDto.File = e.file;
        },
        ...mapActions([
            "deleteLesson",
            "updateVideoLesson",
            "updateFileLesson",
            "uploadVideoToLive",
            "updateLiveLesson",
            "finishLiveLesson",
            "addTeacherAnswer",
        ]),
        getFile(e) {
            this.lessonDto.ThumbFile = e.file;
        },
        getLessonFile(e) {
            if (e && e.file) {
                this.uploadLiveLesson.file = e.file;
            }
        },
        submitTeacherQuestion() {
            this.addTeacherAnswer({
                lessonId: this.lessonId,
                content: this.answer,
            });
            this.answer = "";
        },
        onPressEnter(e) {
            if (e.key === "Enter" && this.answer) {
                // this.submitTeacherQuestion();
            }
        },
        getLessonThumb(e) {
            if (e && e.file) {
                this.uploadLiveLesson.thumbFile = e.file;
            }
        },
        formate(scheduleDate) {
            let date = `${new Date(scheduleDate).toLocaleDateString()}`;
            return date;
        },
        handleOk() {
            this.$refs.form.validate().then((succ) => {
                if (succ) {
                    this.uploadVideoToLive({
                        lessonId: this.lessonDto.id,
                        file: this.uploadLiveLesson.file,
                        thumbFile: this.uploadLiveLesson.thumbFile,
                    });
                    this.$refs.form.reset();
                }
            });
        },
        onUpdate() {
            if (this.lessonType == 1) {
                console.log(this.lessonDto.ThumbFile);
                this.updateVideoLesson({
                    ...this.lessonDto,
                    ThumbFile: this.lessonDto.ThumbFile,
                    File: this.lessonDto.File,
                });
            } else if (this.lessonType == 2) {
                this.updateFileLesson(this.lessonDto);
            } else if (this.lessonType == 4) {
                this.updateLiveLesson({
                    id: this.lessonDto.id,
                    order: this.lessonDto.order,
                    unitId: this.lessonDto.unitId,
                    name: this.lessonDto.name,
                    description: this.lessonDto.description,
                    scheduleDate: this.lessonDto.scheduleDate.replace(" ", "T"),
                    url: this.lessonDto.url,
                });
            }
        },

        onNewQuestion(payload) {
            this.$store.commit("PUSH_STUDENT_QUESTION", {
                ...payload,
                studentName: payload.teacherName,
            });
        },
    },
    created() {
        const connection = new HubConnectionBuilder()
            .withUrl(`${this.$store.getters["app/domainHost"]}/Live`)
            .configureLogging(LogLevel.Information)
            .build();
        connection.on("NewQuestion", (data) => {
            this.onNewQuestion(data);
        });

        connection.on("TeacherComment", (data) => {
            this.onNewQuestion(data);
        });

        if (this.lessonType == 4) {
            connection.start().then(() => {
                connection.invoke("JoinLiveLesson", this.lessonId);
            });
        }

        // this.uploadLiveLesson.lessonId = this.lessonId;
        // this.signalRConnection = new signalr.HubConnectionBuilder().withUrl("/chat").build();
        // console.log(this.signalRConnection);
    },
    mounted() {
        addEventListener("keydown", this.onPressEnter);
    },
};
</script>

<style lang="scss">
.document-lesson {
    video {
        max-height: 350px;
    }
}

.border-card {
    border: 1px solid #cbcbcb;
}

.question-image {
    .preview {
        display: flex;
        justify-content: center;

        img {
            height: 150px;
            width: 100%;
        }
    }
}
</style>
