<template>
    <div>
        <b-card body-class="p-75">
            <b-row>
                <b-col>
                    <div class="d-flex align-items-center">
                        <label class="mx-1">اسم الدرس</label>
                        <ek-input-text
                            class="flex-grow-1"
                            v-model="lessonDto.name"
                            name="اسم الدرس"
                        ></ek-input-text>
                    </div>
                </b-col>
                <b-col>
                    <div class="d-flex align-items-center">
                        <label class="mx-1">تابع للبحث</label>
                        <ek-input-select
                            class="w-auto flex-grow-1"
                            :options="chapterOptions"
                            v-model="lessonDto.chapterId"
                            name="تابع للبحث"
                        >
                            <template #selected-option="value">
                                {{ value.data.name.split("-")[0] }}
                            </template>
                        </ek-input-select>
                    </div>
                </b-col>
                
                    <b-col lg="4" md="6">
                        <b-form-group label="حالة الدرس">
                            <b-form-radio-group
                                id="radio-isHidden"
                                :options="options"
                                v-model="lessonDto.isFree"
                                name="حالة الدرس"
                            >
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
             
                <b-col cols="2">
                    <div class="d-flex align-items-center">
                        <label class="mx-1">الترتيب</label>
                        <ek-input-text
                            type="number"
                            class="flex-grow-1"
                            :options="unitsOptions"
                            v-model="lessonDto.order"
                            name="ترتيب الدرس"
                        ></ek-input-text>
                    </div>
                </b-col>
                <b-col cols="1">
                    <div class="d-flex align-items-center w-100 h-100">
                        <b-button @click="onUpdate" variant="outline-primary"
                            >تعديل</b-button
                        >
                    </div>
                </b-col>
                <b-col cols="2" v-if="lessonType == 3">
                    <div class="d-flex align-items-center w-100 h-100">
                        <b-button v-b-modal.modal-1 variant="outline-primary"
                            >استيراد من اكسل</b-button
                        >
                        <b-modal
                            @ok="importExcel"
                            id="modal-1"
                            title="استيراد من الإكسل"
                        >
                            <div>
                                <ek-input-image
                                    ref="inputFile"
                                    @input="updateExcel"
                                >
                                    <h5>{{ "اسحب الملف او انقر للتحميل " }}</h5>
                                </ek-input-image>
                            </div>
                        </b-modal>
                    </div>
                </b-col>
            </b-row>
        </b-card>

        <component
            :lessonId="id"
            :is="activeComponent"
            :lessonType="lessonType"
        ></component>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DocumentLesson from "@admin/lessons/components/lessonDetails/DocumentLesson";
import QuizLesson from "@admin/lessons/components/lessonDetails/QuizLesson.vue";
import router from "@/router";

export default {
    components: {
        DocumentLesson,
        QuizLesson,
    },
    data() {
        return {
            options: [
                { text: "مجاني", value: true },
                { text: "مدفوع حسب الباقة", value: false },
            ],
          
        };
    },
    props: ["id", "lessonId"],

    computed: {
        activeComponent() {
            let type = this.$route.query.lessonType;
            return type < 3 || type == 4 ? DocumentLesson : QuizLesson;
        },
        lessonType() {
            return this.$route.query.lessonType;
        },
        ...mapState({
            chapterOptions: ({ chapter }) => chapter.chapterOptions,

            lessonDto: ({ lessons }) => lessons.lessonDto,
            unitsOptions: ({ units }) => units.unitsOptions,
            importQuestionDto: ({ questionsBank }) =>
                questionsBank.importQuestionDto,
        }),
    },
    methods: {
        ...mapActions([
            "getLessonVideo",
            "getLessonFile",
            "getLessonQuiz",
            "getLessonLive",
            "decryptFile",
            "updateVideoLesson",
            "updateFileLesson",
            "updateQuizLesson",
            "importQuestion",
            "getChapterOptions",
        ]),
        onUpdate() {
            this.updateQuizLesson(this.lessonDto);
        },
        updateExcel({ file }) {
            this.importQuestionDto.file = file;
        },
        importExcel() {
            if (this.importQuestionDto.file) {
                this.importQuestionDto.lessonId = this.lessonDto.id;
                this.importQuestion(this.importQuestionDto).then(() => {
                    (this.importQuestionDto.lessonId = ""),
                        (this.importQuestionDto.file = ""),
                        router.go(-1);
                });
            }
        },
    },
    created() {
        if (this.lessonType == 1) {
            this.getLessonVideo(this.id).then((res) => {
                this.decryptFile(res.url);
            });
        } else if (this.lessonType == 2)
            this.getLessonFile(this.id).then((res) => {
                this.decryptFile(res.url);
            });
        else if (this.lessonType == 3) this.getLessonQuiz(this.id);
        else this.getLessonLive(this.id);
        this.getChapterOptions();
    },
};
</script>

<style></style>
