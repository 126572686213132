<template>
    <b-card>
        <template #header>
            <div class="fit flex-between">
                <div class="text" v-if="lessonType == 1">
                    <strong>الاسئلة على الفيديو</strong>
                </div>
                <div class="text" v-else>
                    <strong>الاسئلة على الملف</strong>
                </div>
                <div class="d-flex align-items-center">
                    <span class="mx-50"> عرض المجاب فقط </span>
                    <b-form-checkbox switch class="d-flex flex-row-reverse"> </b-form-checkbox>
                </div>
            </div>
        </template>
        <div v-if="quistions && quistions.length">
            <b-list-group>
                <b-list-group-item v-for="item in quistions" :key="item.id"
                    class="my-50 rounded-lg overflow-hidden shadow">
                    <b-row>
                        <b-col cols="12">
                            <div class="fit flex-between">
                                <span>
                                    <unicon name="calendar-alt" fill="gray" height="16"></unicon>
                                    {{ formDate(item.dateCreated) }}
                                </span>
                                <span>
                                    <b-badge pill :variant="item.isAnswered ? 'success' : 'danger'">
                                        {{
                                                item.isAnswered ? " مجاب" : " غير مجاب"
                                        }}
                                    </b-badge>
                                </span>
                            </div>
                        </b-col>
                        <b-col cols="12" class="my-50">
                            <p class="m-0">
                                {{ item.content }}
                            </p>
                        </b-col>
                        <b-col cols="12">
                            <div class="fit">
                                <b-button variant="flat" class="p-0" :to="`/admin/lessons/questions/${item.id}`">
                                    عرض التفاصيل
                                    <unicon name="angle-left" fill="gray"></unicon>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div v-else class="d-flex justify-content-center">
            <h5>لا يوجد اسئلة</h5>
        </div>
    </b-card>
</template>

<script>
export default {
    props: ["lessonType", "quistions", "lessonId"],
    methods: {
        formDate(dateCreated) {
            let date = `${new Date(dateCreated).toLocaleDateString()}`;
            return date;
        },
    }
};
</script>

<style>
</style>
