<template>
  <div>
    <!-- <ek-table
      :columns="headers"
      :items="questions"
      :isPlus="isPlus"
      @plus="goToNewQuiz"
      @details="goToDetails"
      @delete-selected="deleteRangeQuestionQuiz"
    >
      <template slot="items.order" slot-scope="{ value }">
        <b-badge pill variant="primary">
          {{ value }}
        </b-badge>
      </template>

      <template slot="items.dateCreated" slot-scope="{ value }">
        {{ new Date(value).toLocaleDateString() }}
      </template> -->

    <!-- <template slot="items.quizId" slot-scope="{ value }">
        {{ quizeOptions.find((ele) => ele.id == value).name }}
      </template> -->
    <!-- </ek-table> -->

    <b-card class="p-0" body-class="p-0" header-class="p-1">
      <template #header>
        <b-button
          variant="flat-primary"
          class="rounded-circle p-25"
          @click="deleteRangeQuestionQuiz(selectedQuestions)"
        >
          <unicon name="trash-alt" fill="gray"></unicon>
        </b-button>
        <b-button
          variant="flat-primary"
          v-if="isPlus"
          @click="goToNewQuiz"
          class="rounded-circle p-25"
        >
          <unicon name="plus" fill="gray"></unicon>
        </b-button>
      </template>
      <vue-good-table
        @on-selected-rows-change="setSelectedQuestions"
        :selectOptions="{
          enabled: true,
        }"
        styleClass="vgt-table"
        :columns="headers"
        :rows="questions"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'order'">
            <b-badge pill variant="primary">
              {{ props.row.order }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field == 'dateCreated'">
            {{ new Date(props.row.dateCreated).toLocaleDateString() }}
          </span>
          <span v-else-if="props.column.field == 'details'">
            <b-button
              @click="goToDetails(props.row)"
              variant="flat-primary"
              class="rounded-circle p-25"
            >
              <unicon name="ellipsis-v" fill="gray"></unicon>
            </b-button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card>
    <!-- <div v-else class="text-center mt-2">
            <h4>لا يوجد اسئلة اختبار</h4>
        </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
export default {
  components: {
    VueGoodTable,
  },
  props: {
    questions: Array,
    headers: Array,
    lessonId: String,
    isPlus: Boolean,
  },
  data() {
    return {
      selectedQuestions: [],
    };
  },

  computed: {
    ...mapState({
      quizeOptions: ({ questionsBank }) => questionsBank.quizeOptions,
    }),
  },
  methods: {
    goToDetails(row) {
      this.$router.push({
        path: `/admin/questionsBank/${this.lessonId || row.quizId}/${row.id}`,
        query: { questionType: row.questionsType },
      });
    },
    goToNewQuiz() {
      this.$router.push({
        path: `/admin/questionsBank/${this.lessonId}/0`,
        query: { questionType: 2 },
      });
    },
    setSelectedQuestions({ selectedRows }) {
      this.selectedQuestions = selectedRows.map((q) => q.id);
    },

    ...mapActions(["deleteRangeQuestionQuiz"]),
  },
};
</script>
